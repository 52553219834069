import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo" 
import DirectoryCategories from "../../components/content/categories" 
import GridIcons from "../../components/content/gridicons" 

import Menu from "../../components/content/menu"
const BusinessPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query CapabilitiesPageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
          <SEO title="Capabilities" />
          <div className="jumbotron page-header">
            <div className="container">
              <h1 className="display-32">Capabilities for your Company</h1>
              <h2 className="display-32">
                Get digital solutions and insights today.{" "}
                <span style={{ color: "#007bff" }}>Focus on what matters.</span>
              </h2>
              <p>
                Your customers expect better, smarter and safer and more
                intelligence, services and solutions to drive results.
              </p>{" "}
              {/* <p>
                <Menu />{" "}
              </p> */}
            </div>
          </div>


          
          <section className="features features-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  {/* <h3>
                    We ready to help
                    </h3> */}

                  <h3>TRANSFORM, INNOVATE &amp; GROW.</h3>

                  <p> 
                    {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                    If you’re looking to build or integrate smart, scalable and
                    secure next generation solutions to accelerate success for
                    your business, we can help.
                    <br />
                    <br />
                  </p>
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="mb40 mb-xs-24">How We help Your Team</h2>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-white">
                      <h5>Roadmap your tech, dev, and&nbsp;team</h5>
                    </div>
                    <div className="col-md-12 text-white">
                      <h5>Find what’s wrong and fix</h5>
                    </div>
                    <div className="col-md-12 text-white">
                      <h5>Help find your gaps, risks &amp; blindspots</h5>
                    </div>
                    <div className="col-md-12 text-white">
                      <h5>Guide projects &amp; team on right path</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-white">
                      <h5>Assess IT resources</h5>
                    </div>
                    <div className="col-md-12 text-white">
                      <h5>Enhance capabilities</h5>
                    </div>
                    <div className="col-md-12 text-white">
                      <h5>Strategy and implementation planning</h5>
                    </div>
                    <div className="col-md-12 text-white">
                      <h5>Build your platforms</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-white">
                      <h5>Optimize your platforms</h5>
                    </div>
                    <div className="col-md-12 text-white">
                      <h5>Harden your platforms</h5>
                    </div>
                    <div className="col-md-12 text-white">
                      <h5>Risk planning</h5>
                    </div>
                    <div className="col-md-12 text-white">
                      <h5>Build technical advantages</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <a className="btn btn-primary  btn-sm" href="/why">
                  Learn More about Why TheLeanFounder Exists?
                </a>

                <div className={"container"}>
                  <div className={"call-to-action__content2"}>
                    {/* <h2>We ready to help</h2> */}
                    <p>
                      {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                      {/* If you’re looking to build or integrate smart, scalable
                        and secure next generation solutions to accelerate
                        success for your business, we can help. */}
                    </p>
                    {/* 
                      <div className="row">
                        <div className="col-md-3">
                          <h3>Engage Customers</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Empower Employees</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Optimize Operations</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Transform Business</h3>
                        </div>
                      </div> */}
                  </div>

                  {/* <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <a
                  className="btn btn-outline-primary mb64 "
                  href="/lets-talk/?service=solutions"
                >
                  Learn More
                </a>
                {/* <a
                                  className="btn btn-outline-primary mb64"
                                >
                                  Learn More
                                </a> */}
                <div className={"container d-none"}>
                  <div className={"call-to-action__content2"}>
                    {/* <h2>We ready to help</h2> */}
                    <p>
                      {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                      {/* If you’re looking to build or integrate smart, scalable
                        and secure next generation solutions to accelerate
                        success for your business, we can help. */}
                    </p>
                    {/* 
                      <div className="row">
                        <div className="col-md-3">
                          <h3>Engage Customers</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Empower Employees</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Optimize Operations</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Transform Business</h3>
                        </div>
                      </div> */}
                  </div>

                  {/* <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> */}
                </div>
              </div>
            </div>
          </section>
        
        
        
          
          <section className="features features-3">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                {/* <h3>
                  We ready to help
                  </h3> */}

                <h3>APPROACH</h3>

                <p className="lead mb64">  We enable agility & maturity for early stage ventures, small businesses and enterprises seeking digital transformation by aligning tailored recommendations prior to investment to yield higher value and return on investment.
                  <br />
                  <br />
                </p>
               </div>
            </div>
            <div className="row">
                                        <div className="col-sm-4 text-center">
                                          <div className="feature">
                                            <i className="icon   inline-block mb16 ti-light-bulb  pb-4 pt-4" />
                                            <h4>VISION TO LAUNCH</h4>
                                            <p>
                                              From idea on the whiteboard to
                                              working code, we can help with
                                              inception, business analysis,
                                              needs analysis, solution mapping,
                                              specs, design, code, servers,
                                              deployment, quality, scalability
                                              &amp; security.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-sm-4 text-center">
                                          <div className="feature">
                                            <i className="icon   inline-block mb16 ti-layout-grid3-alt pb-4 pt-4" />
                                            <h4>AGILE DEVELOPMENT</h4>
                                            <p>
                                              You have an idea, but need
                                              precision refinement or questions
                                              answered to assess feasibility and
                                              viability or you already have
                                              existing investments, teams and
                                              platform but need better
                                              strategies, processes and
                                              capabilities to succeed.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-sm-4 text-center">
                                          <div className="feature">
                                            <i className="icon  inline-block mb16 ti-loop pb-4 pt-4" />
                                            <h4>GAP ANALYSIS</h4>
                                            <p>
                                              Based on years of working with the
                                              best standards &amp; technologies,
                                              we can provide 360-degree coverage
                                              with tailored recommendations on
                                              your business, team and project.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                 
          </div>
       
        </section>
        
  <a id="home" className="in-page-link" href="#" />
 
                    <section className="image-bg overlay parallax  bg-primary normal-padding vc_row wpb_row vc_row-fluid bg-dark normal-padding vc_custom_1489362133434 d-none">
                      <div
                        className="background-image-holder"
                        style={{
                          "-webkit-transform": "translate3d(0px, 363.5px, 0px)",
                          "-ms-transform": "translate3d(0px, 363.5px, 0px)",
                          transform: "translate3d(0px, 363.5px, 0px)",
                        }}
                      >
                        <img
                          alt="Background"
                          className="background-image"
                          src="/images/outerearth.jpg"
                          data-recalc-dims={1}
                        />
                      </div>
                      <div className="container">
                        <div className="row ">  
                          <div className=" col-md-12">  <h3
                                      style={{
                                        "-webkit-text-align": "center",
                                        "text-align": "center",
                                      }}
                                    >
                                      Some amazing companies we’ve had the
                                      pleasure to work with.
                                    </h3>  </div> </div>
                        <div className="row ">
                          <div className="wpb_column vc_column_container col-md-3">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper" />
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container col-md-6">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                 
                                  </div>
                                </div>


                                <div className="container">
                    <div
                      id="carouselContent"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" role="listbox">
                          <div
                          className="carousel-item active text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                          <p>
                                        “Unquestionable integrity and is highly
                                        responsive and customer focused.”
                                      </p>
                                      <div className="quote-author">
                                        <h6 className="uppercase">
                                          Margie Warrell
                                        </h6>
                                        <span>
                                          Speaker, Author, Forbes Columnist
                                        </span>{" "}
                                      </div>
                          </p>
                        </div>

                        <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                          <p>
                                        “Provided the needed expertise to
                                        complete the work to a high quality and
                                        on-time.”
                                      </p>
                                      <div className="quote-author">
                                        <h6 className="uppercase">
                                          Chris Parker
                                        </h6>
                                        <span>
                                          Co-Founder &amp; CEO, 4Front Security
                                          acquired by Symantec
                                        </span>{" "}
                                      </div>
                          </p>
                        </div>
                        <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                          <p>
                                        “Their work is outstanding and they are
                                        good friends of AFF.”
                                      </p>
                                      <div className="quote-author">
                                        <h6 className="uppercase">
                                          Deanna Lutz
                                        </h6>
                                        <span>
                                          Former Director of Operations, Alice
                                          Ferguson Foundation
                                        </span>{" "}
                                      </div>
                          </p>
                        </div>

                        <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                          <p>“Awesome.”</p>
                                      <div className="quote-author">
                                        <h6 className="uppercase">
                                          Ralph Cavallero
                                        </h6>
                                        <span>
                                          Founder &amp; CEO, NEW REACH
                                          CONSULTING, Former VP of UMG
                                        </span>{" "}
                                      </div>
                          </p>
                        </div>
                        <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                          <p>
                                        “These guys jumped in when we had
                                        immediate needs and dedicated time to
                                        helping us reach our objectives.”
                                      </p>
                                      <div className="quote-author">
                                        <h6 className="uppercase">
                                          Roger London
                                        </h6>
                                        <span>
                                          Founder &amp; CEO, TechMatch &amp;
                                          American Security Challenge
                                        </span>{" "}
                                      </div>
                          </p>
                        </div>
                          <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                          <p>
                                        “TheLeanFounder’s commitment to helping us
                                        build the best product comes through
                                        every time. We’ve reduced burn rates by
                                        90% and increased profit margins.
                                        They’re a great partner &amp;
                                        invaluable!”
                                      </p>
                                      <div className="quote-author">
                                        <h6 className="uppercase">
                                          Todd Friedman
                                        </h6>
                                        <span>
                                          Board Member Music.com, CISO ResMed,
                                          Formerly CISO of UMG, CISO of Mattell,
                                        </span>{" "}
                                      </div>
                          </p>
                        </div>
                          <div
                          className="carousel-item text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                          <p>
                                        “From start of the process to
                                        post-launch, UVISION has been
                                        outstanding. They listened to our ideas
                                        &amp; used their creativity &amp;
                                        expertise to expand upon those ideas. I
                                        would recommend TheLeanFounder to any company,
                                        business or organization.”
                                      </p>
                                      <div className="quote-author">
                                        <h6 className="uppercase">
                                          Ed Nuttall
                                        </h6>
                                        <span>
                                          Managing Partner, BHNL Law Firm
                                        </span>{" "}
                                      </div>
                          </p>
                        </div>
                        <div
                          className="carousel-item text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                          <p>
                                        “The TheLeanFounder team is knowledgeable,
                                        accommodating, and professional.”
                                      </p>
                                      <div className="quote-author">
                                        <h6 className="uppercase">
                                          Stephen Taylor
                                        </h6>
                                        <span>
                                          Angel Investor, VC, Founder &amp;
                                          Managing Partner, District Ventures
                                        </span>{" "}
                                      </div>
                          </p>
                        </div>
                      
                       
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#carouselContent"
                        role="button"
                        data-slide="prev"
                      >
                        {/* <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        /> */}
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#carouselContent"
                        role="button"
                        data-slide="next"
                      >
                        {/* <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        /> */}
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>


                                
                              
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container col-md-3">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className=" d-none  bg-dark fullscreen vc_row wpb_row vc_row-fluid bg-dark fullscreen">
                      <div className="container v-align-transform">
                        <div className="row ">
                          <div className="wpb_column vc_column_container col-md-3">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper" />
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container col-md-6">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <h3
                                      style={{
                                        "-webkit-text-align": "center",
                                        "text-align": "center",
                                      }}
                                    >
                                      A few of the solutions we’ve helped our
                                      clients develop.
                                    </h3>
                                  </div>
                                </div>
                                <div
                                  className="vc_tta-container vc_tta-o-non-responsive"
                                  data-vc-action="collapse"
                                  data-vc-tta-autoplay='{"delay":10000}'
                                >
                                  <div className="vc_general vc_tta vc_tta-tabs vc_tta-o-shape-group vc_tta-has-pagination vc_tta-o-no-fill vc_tta-tabs-position-top  vc_tta-pageable">
                                    <div className="vc_tta-panels-container">
                                      <ul className="vc_general vc_pagination vc_pagination-style-flat vc_pagination-shape-rounded vc_pagination-color-grey">
                                        <li
                                          className="vc_pagination-item vc_active"
                                          data-vc-tab
                                        >
                                          <a
                                            href="#1520390418134-458b8b82-2e2a"
                                            className="vc_pagination-trigger"
                                            data-vc-tabs
                                            data-vc-container=".vc_tta"
                                          />
                                        </li>
                                        <li
                                          className="vc_pagination-item"
                                          data-vc-tab
                                        >
                                          <a
                                            href="#1520390418218-7f9a9320-d31e"
                                            className="vc_pagination-trigger"
                                            data-vc-tabs
                                            data-vc-container=".vc_tta"
                                          />
                                        </li>
                                        <li
                                          className="vc_pagination-item"
                                          data-vc-tab
                                        >
                                          <a
                                            href="#1520391504915-7028ee22-d772"
                                            className="vc_pagination-trigger"
                                            data-vc-tabs
                                            data-vc-container=".vc_tta"
                                          />
                                        </li>
                                        <li
                                          className="vc_pagination-item"
                                          data-vc-tab
                                        >
                                          <a
                                            href="#1520395965506-e8da1b51-41f2"
                                            className="vc_pagination-trigger"
                                            data-vc-tabs
                                            data-vc-container=".vc_tta"
                                          />
                                        </li>
                                        <li
                                          className="vc_pagination-item"
                                          data-vc-tab
                                        >
                                          <a
                                            href="#1520396089245-0edbdc4c-4b10"
                                            className="vc_pagination-trigger"
                                            data-vc-tabs
                                            data-vc-container=".vc_tta"
                                          />
                                        </li>
                                      </ul>
                                      <div className="vc_tta-panels">
                                        <div
                                          className="vc_tta-panel vc_active"
                                          id="1520390418134-458b8b82-2e2a"
                                          data-vc-content=".vc_tta-panel-body"
                                        >
                                          <div className="vc_tta-panel-body">
                                            <span className="vc_tta-panel-title">
                                              <a
                                                data-vc-container=".vc_tta-container"
                                                data-vc-accordion
                                                data-vc-target="#1520390418134-458b8b82-2e2a"
                                              />
                                            </span>
                                            <div className="wpb_text_column wpb_content_element ">
                                              <div className="wpb_wrapper">
                                                <p className="lead">
                                                  <strong>
                                                    Developing and supporting
                                                    Music Platform
                                                  </strong>
                                                </p>
                                                <p>
                                                  <strong>Challenge</strong>: A
                                                  semantic music data platform,
                                                  needed an affordable way of
                                                  extending capabilities &amp;
                                                  reducing costs while competing
                                                  with MySpace and Youtube,
                                                  requiring agility, innovation
                                                  &amp; efﬁciency.
                                                </p>
                                                <p>
                                                  <strong>Solution</strong>:
                                                  UVISION provided services for
                                                  improving platforms &amp;
                                                  reducing burn rates by 90%
                                                  with leased/collocated servers
                                                  migrated to cloud. Built BI
                                                  dashboards, data integration,
                                                  CMS, video streaming, music
                                                  distribution &amp; analytics.
                                                  Protected domain asset, site
                                                  &amp; improved brand over 11
                                                  years of business iterations.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="vc_tta-panel"
                                          id="1520390418218-7f9a9320-d31e"
                                          data-vc-content=".vc_tta-panel-body"
                                        >
                                          <div className="vc_tta-panel-body">
                                            <span className="vc_tta-panel-title">
                                              <a
                                                data-vc-container=".vc_tta-container"
                                                data-vc-accordion
                                                data-vc-target="#1520390418218-7f9a9320-d31e"
                                              />
                                            </span>
                                            <div className="wpb_text_column wpb_content_element ">
                                              <div className="wpb_wrapper">
                                                <p className="lead">
                                                  <strong>
                                                    Designing anti-tampering
                                                    measures for U.S. military
                                                    electronic assets{" "}
                                                  </strong>
                                                </p>
                                                <p>
                                                  <strong>Challenge</strong>:
                                                  Electronic assets used by
                                                  military may be tampered with
                                                  along global supply chains in
                                                  foreign countries resulting in
                                                  huge possible security and
                                                  privacy risks for the U.S.
                                                  military and our society.
                                                </p>
                                                <p>
                                                  <strong>Solution</strong>:
                                                  UVISION worked with defense
                                                  contractors working on this
                                                  DoD advanced research project
                                                  to deﬁne &amp; design a
                                                  solution architecture for a
                                                  global, scalable enterprise
                                                  system enabling secure,
                                                  encrypted audit capabilities
                                                  for tracking tampering of
                                                  electronic assets in global
                                                  defense supply chains.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="vc_tta-panel"
                                          id="1520391504915-7028ee22-d772"
                                          data-vc-content=".vc_tta-panel-body"
                                        >
                                          <div className="vc_tta-panel-body">
                                            <span className="vc_tta-panel-title">
                                              <a
                                                data-vc-container=".vc_tta-container"
                                                data-vc-accordion
                                                data-vc-target="#1520391504915-7028ee22-d772"
                                              />
                                            </span>
                                            <div className="wpb_text_column wpb_content_element ">
                                              <div className="wpb_wrapper">
                                                <p className="lead">
                                                  <strong>
                                                    Rapid Risk Assessments and
                                                    Regulated-Industry
                                                    Compliance Risk Analysis
                                                  </strong>
                                                </p>
                                                <p>
                                                  <strong>Challenge</strong>:
                                                  Large and heavily regulated
                                                  industries have thousands of
                                                  potential regulatory risks.
                                                  Standards also need to be
                                                  adhered to and trained against
                                                  for critical requirements
                                                  &amp; gaps for the company
                                                </p>
                                                <p>
                                                  <strong>Solution</strong>:
                                                  UVISION helped a security and
                                                  risk consulting company
                                                  rapidly develop an on-premise
                                                  .NET enterprise gap analysis
                                                  data product for regulated
                                                  industries to assess gaps for
                                                  300+ standard within 4 months
                                                  later resulting in Symantec
                                                  acquisition at $9M the next
                                                  year.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="vc_tta-panel"
                                          id="1520395965506-e8da1b51-41f2"
                                          data-vc-content=".vc_tta-panel-body"
                                        >
                                          <div className="vc_tta-panel-body">
                                            <span className="vc_tta-panel-title">
                                              <a
                                                data-vc-container=".vc_tta-container"
                                                data-vc-accordion
                                                data-vc-target="#1520395965506-e8da1b51-41f2"
                                              />
                                            </span>
                                            <div className="wpb_text_column wpb_content_element ">
                                              <div className="wpb_wrapper">
                                                <p className="lead">
                                                  <strong>
                                                    Matching Venture Funded
                                                    Companies to Enterprises{" "}
                                                  </strong>
                                                </p>
                                                <p>
                                                  <strong>Challenge</strong>: A
                                                  platform that matches VC
                                                  funded high tech companies to
                                                  large enterprises and stock
                                                  markets needed ﬁxes for
                                                  critical sponsor &amp;
                                                  customer needs including State
                                                  of Maryland (Invest Maryland
                                                  Challenge 2015) for system
                                                  used by Startup America &amp;
                                                  NYSE, D&amp;B to name a few.
                                                  The platform matches venture
                                                  company data to the right
                                                  enterprise decision makers
                                                  targeting marketing to,
                                                  procuring from, merging with
                                                  or acquiring high tech
                                                  companies.
                                                </p>
                                                <p>
                                                  <strong>Solution</strong>:
                                                  UVISION helped improve all
                                                  areas of the product &amp;
                                                  system (scoring, ratings,
                                                  matching, analytics, search,
                                                  queueing, stability, quality,
                                                  scalability), new features,
                                                  deﬁned roadmaps &amp; cut
                                                  infrastructure burn rates 75%.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="vc_tta-panel"
                                          id="1520396089245-0edbdc4c-4b10"
                                          data-vc-content=".vc_tta-panel-body"
                                        >
                                          <div className="vc_tta-panel-body">
                                            <span className="vc_tta-panel-title">
                                              <a
                                                data-vc-container=".vc_tta-container"
                                                data-vc-accordion
                                                data-vc-target="#1520396089245-0edbdc4c-4b10"
                                              />
                                            </span>
                                            <div className="wpb_text_column wpb_content_element ">
                                              <div className="wpb_wrapper">
                                                <p className="lead">
                                                  <strong>
                                                    Cleaning up the Washington
                                                    Area Potomac Watershed{" "}
                                                  </strong>
                                                </p>
                                                <p>
                                                  <strong>Challenge</strong>:
                                                  The Washington DC area Potomac
                                                  Watershed Clean Up program
                                                  helps clean up trash with 15K
                                                  volunteers annually with many
                                                  local organizations partnered
                                                  in tracking data in one
                                                  system.
                                                </p>
                                                <p>
                                                  <strong>Solution</strong>:
                                                  UVISION developed the solution
                                                  for registration of users at
                                                  all sites to allow users to
                                                  submit clean up data to
                                                  aggregate and visualize and
                                                  report instantly and also
                                                  integrates with National
                                                  Geographic FieldScope map.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container col-md-3">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>



          
                    <section className="features features-3">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                {/* <h3>
                  We ready to help
                  </h3> */}

                <h3>Why Work With Us</h3>

                <p>   We enable agility & maturity for early stage ventures, small businesses and enterprises seeking digital transformation by aligning tailored recommendations prior to investment to yield higher value and return on investment.
              
                  <br />
                  <br />
                </p>
               </div>
            </div>
          </div>
          <div className="row">
          <div className="container">
          <div id="about1" className="row">
                                      <div className="col-md-4">
                                        <h3>Determination</h3>
                                        <p>
                                          We have a wealth of design and
                                          development experience. We like to be
                                          involved from idea to iteration and
                                          put as much emphasis on our
                                          consultancy and management as we do on
                                          the actual build and launch. We are
                                          driven to solve problems and we enjoy
                                          working with people and clients who
                                          value high standards, teamwork and
                                          results.
                                        </p>
                                      </div>
                                      <div className="col-md-4">
                                        <h3>Collaboration</h3>
                                        <p>
                                          You can trust knowing that we are
                                          giving our best, and we trust you that
                                          you will do the same.
                                          <br />
                                          We have worked in a variety of
                                          capacities from partnering with client
                                          resources to assembling and leading
                                          teams best suited to a specific
                                          project or task.
                                        </p>
                                      </div>
                                      <div className="col-md-4">
                                        <h3>Communication</h3>
                                        <p>
                                          Communication is the key to project
                                          success. We devote a great deal of our
                                          attention and process to it.
                                          <br />
                                          We meet with our clients regularly to
                                          discuss the next steps in a project.
                                          We constantly show you our progress
                                          and collect your feedback.
                                        </p>
                                      </div>
                                    </div>
                                    <div id="about1" className="row">
                                      <div className="col-md-4">
                                        <h3>Passion</h3>
                                        <p>
                                          We are passionate about technology
                                          &amp; agility to create meaningful
                                          value for awesome clients.
                                          <br />
                                          Our backgrounds include engineering,
                                          business, computer science,
                                          communications, and of course, a
                                          little music.
                                        </p>
                                      </div>
                                      <div className="col-md-4">
                                        <h3>Expertise</h3>
                                        <p>
                                          TheLeanFounder’s core team is made up of
                                          experts in lean strategy, engineering
                                          and innovation. Our experience gives
                                          us insight into the challenges you’ll
                                          face as you develop your digital
                                          strategy and accelerate your
                                          execution.
                                        </p>
                                      </div>
                                      <div className="col-md-4">
                                        <h3>Experience</h3>
                                        <p>
                                          Our team has helped many great
                                          organizations — working with diverse
                                          audiences, markets and verticals
                                          delivering technology solutions,
                                          business applications and interactive
                                          web sites. Regardless of scope, we’re
                                          obsessed with making everything we do
                                          fun and usable.
                                        </p>
                                      </div>
                                    </div>
                                    <div id="about1" className="row">
                                      <div className="col-md-4">
                                        <h3>Continuous Agility</h3>
                                        <p>
                                          With agile execution methods we build
                                          valuable features and interactivity,
                                          from minimum viable products to robust
                                          products.
                                          <br />
                                          Instead of spending weeks on
                                          documentation that will be outdated
                                          soon due to changing requirements, we
                                          give you access to your software as
                                          soon as possible.
                                        </p>
                                      </div>
                                      <div className="col-md-4">
                                        <h3>Continuous Improvement</h3>
                                        <p>
                                          We devote one day per week to
                                          continuous innovation and improvement
                                          to challenge ourselves and to find
                                          time to solve problems that make a
                                          difference and help others.
                                        </p>
                                      </div>
                                      <div className="col-md-4">
                                        <h3>Automation</h3>
                                        <p>
                                          With best practices, agile tools,
                                          automation and functional and
                                          acceptance testing built into our
                                          development process, we reduce profit
                                          loss when your software isn’t working
                                          &amp; maintenance costs eating up
                                          project budgets.
                                        </p>
                                      </div>
                                    </div>
                              
          </div>
          </div>
        </section>
      
                   
                    <section className=" d-none bg-dark normal-padding vc_row wpb_row vc_row-fluid bg-dark normal-padding">
                      <div className="container ">
                        <div className="row ">
                          <div className="wpb_column vc_column_container col-md-3">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper" />
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container col-md-6">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper pt-6 pb-6">
                                    <h3
                                      style={{
                                        "-webkit-text-align": "center",
                                        "text-align": "center",
                                      }}
                                    >
                                      Start building more
                                      amazing&nbsp;applications&nbsp;today.
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container col-md-3">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className=" d-none  bg-secondary normal-padding vc_row wpb_row vc_row-fluid bg-secondary normal-padding">
                      <div className="container ">
                        <div className="row ">
                          <div className="wpb_column vc_column_container col-md-12">
                            <div className="vc_column-inner ">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <h4
                                      className="uppercase"
                                      style={{
                                        "-webkit-text-align": "center",
                                        "text-align": "center",
                                      }}
                                    >
                                      A TEAM&nbsp;YOU CAN COUNT ON
                                    </h4>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                  <div className="wpb_column vc_column_container col-md-2">
                                    <div className="vc_column-inner ">
                                      <div className="wpb_wrapper" />
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container col-md-4">
                                    <div className="vc_column-inner ">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <h1
                                              className="large mb8"
                                              style={{
                                                "-webkit-text-align": "center",
                                                "text-align": "center",
                                              }}
                                            >
                                              $50M+
                                            </h1>
                                            <h6
                                              className="uppercase"
                                              style={{
                                                "-webkit-text-align": "center",
                                                "text-align": "center",
                                              }}
                                            >
                                              IN VALUE GENERATED&nbsp;FOR
                                              CLIENTS
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container col-md-4">
                                    <div className="vc_column-inner ">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <h1
                                              className="large mb8"
                                              style={{
                                                "-webkit-text-align": "center",
                                                "text-align": "center",
                                              }}
                                            >
                                              Limitless
                                            </h1>
                                            <h6
                                              className="uppercase"
                                              style={{
                                                "-webkit-text-align": "center",
                                                "text-align": "center",
                                              }}
                                            >
                                              THE&nbsp;POTENTIAL
                                              USING&nbsp;UVISION
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container col-md-2">
                                    <div className="vc_column-inner ">
                                      <div className="wpb_wrapper" />
                                    </div>
                                  </div>
                                </div>
                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                  <div className="wpb_column vc_column_container col-md-2">
                                    <div className="vc_column-inner ">
                                      <div className="wpb_wrapper" />
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container col-md-8">
                                    <div className="vc_column-inner ">
                                      <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element ">
                                          <div className="wpb_wrapper">
                                            <p>
                                              We are a technology development
                                              company developing next generation
                                              technologies and a solutions
                                              provider providing
                                              transformative&nbsp;solutions and
                                              services to clients spanning from
                                              small businesses to
                                              enterprises&nbsp;to government.
                                            </p>
                                            <p>
                                              At TheLeanFounder, we help
                                              organizations&nbsp;leverage next
                                              generation technologies as an
                                              accelerator for growth. We work as
                                              a technology advisor and
                                              outsourced development partner for
                                              the public and private sector from
                                              startups to
                                              enterprises&nbsp;bringing digital
                                              expertise from solution
                                              architecture, scaling, security,
                                              testing, cloud infrastructure
                                              &amp; life-cycle management. We
                                              help organizations build
                                              innovative solutions, solve
                                              problems, capture &amp; analyze
                                              data, enhance productivity, gain
                                              business intelligence, improve
                                              bottom line, mitigate risks &amp;
                                              be more resilient.
                                            </p>
                                            <p>
                                              We’ve helped develop solutions in
                                              industries such as B2B, B2G, B2C,
                                              fin-tech, legal, e-discovery,
                                              health care, education,
                                              e-learning, retail, e-commerce,
                                              m-commerce, kiosk, hospitality,
                                              travel, real estate,
                                              transportation, infrastructure,
                                              construction, oil and gas,
                                              manufacturing, nonprofit,
                                              marketing, social media, music
                                              &amp; entertainment.
                                            </p>
                                            <p>
                                              We’re partnered with Microsoft,
                                              IBM and others. We also support
                                              Google, AWS, Azure and other
                                              platforms.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wpb_column vc_column_container col-md-2">
                                    <div className="vc_column-inner ">
                                      <div className="wpb_wrapper" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
            
          <section className="features features-3 d-none">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  {/* <h3>
                    We ready to help
                    </h3> */}

 
{/* 
                  <p>
                    <br />
                    <br />
                    
                    If you’re looking to build or integrate smart, scalable and
                    secure next generation solutions to accelerate success for
                    your business, we can help.
                    <br />
                    <br />
                  </p> */}
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="mb40 mb-xs-24 pt-6 pb-6">How We help Your Team</h2>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 text-white">
                      <h4>Roadmap your tech, dev, and&nbsp;team</h4>
                    </div>
                    <div className="col-md-3 text-white">
                      <h4>Find what’s wrong and fix</h4>
                    </div>
                    <div className="col-md-3 text-white">
                      <h4>Help find your gaps, risks &amp; blindspots</h4>
                    </div>
                    <div className="col-md-3 text-white">
                      <h4>Guide projects &amp; team on right path</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 text-white">
                      <h4>Assess IT resources</h4>
                    </div>
                    <div className="col-md-3 text-white">
                      <h4>Enhance capabilities</h4>
                    </div>
                    <div className="col-md-3 text-white">
                      <h4>Strategy and implementation planning</h4>
                    </div>
                    <div className="col-md-3 text-white">
                      <h4>Build your platforms</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 text-white">
                      <h4>Optimize your platforms</h4>
                    </div>
                    <div className="col-md-3 text-white">
                      <h4>Harden your platforms</h4>
                    </div>
                    <div className="col-md-3 text-white">
                      <h4>Risk planning</h4>
                    </div>
                    <div className="col-md-3 text-white">
                      <h4>Build technical advantages</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <a className="btn btn-primary  btn-sm" href="/why">
                  Learn More about Why TheLeanFounder Exists?
                </a>

                <div className={"container"}>
                  <div className={"call-to-action__content2"}>
                    {/* <h2>We ready to help</h2> */}
                    <p>
                      {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                      {/* If you’re looking to build or integrate smart, scalable
                        and secure next generation solutions to accelerate
                        success for your business, we can help. */}
                    </p>
                    {/* 
                      <div className="row">
                        <div className="col-md-3">
                          <h3>Engage Customers</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Empower Employees</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Optimize Operations</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Transform Business</h3>
                        </div>
                      </div> */}
                  </div>

                  {/* <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <a
                  className="btn btn-outline-primary mb64 "
                  href="/lets-talk/?service=solutions"
                >
                  Learn More
                </a>
                {/* <a
                                  className="btn btn-outline-primary mb64"
                                >
                                  Learn More
                                </a> */}
                <div className={"container d-none"}>
                  <div className={"call-to-action__content2"}>
                    {/* <h2>We ready to help</h2> */}
                    <p>
                      {/* Sign up to start improving your digital world with {data.site.siteMetadata.title}. */}
                      {/* If you’re looking to build or integrate smart, scalable
                        and secure next generation solutions to accelerate
                        success for your business, we can help. */}
                    </p>
                    {/* 
                      <div className="row">
                        <div className="col-md-3">
                          <h3>Engage Customers</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Empower Employees</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Optimize Operations</h3>
                        </div>
                        <div className="col-md-3">
                          <h3>Transform Business</h3>
                        </div>
                      </div> */}
                  </div>

                  {/* <div className={"button"}>
              <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Let's Talk
                </Link>
              </div> */}
                </div>
              </div>
            </div>
          </section>

    


          <section className="features features-3 d-none">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">
                  <h3>
                    Highlight your organizations privacy, policy &amp; cyber
                    security standards.
                  </h3>
                  {/* <h4>
              We help your team save time by pinpointing unclear and potentially
              risky parameters and information where data privacy and personal
              privacy terms are in user agreements and policy documents.
            </h4> */}
                  <p>
                    By proactively monitoring, disclosing, annotating and
                    analyzing your own organization and your vendor's, you can
                    ensure a better future for your customers, team and bottom
                    line.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center feature">
                  <i className="icon-bag" />
                  <h5>Build Trust</h5>
                  <p>
                    Show how your organization is working towards ensuring data
                    privacy that real people, customers &amp; regulators care
                    about. Disclose and showcase personal data privacy
                    parameters related to your app or service's privacy policies
                    as well as compliance 24/7.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-graph" />
                  <h5>Highlight Standards</h5>
                  <p>
                    Based on the most modern web standards, devices, platforms,
                    operating systems and eco systems from PC, mobile, IoT, AI,
                    XR &amp; more, highlight how your company handles data
                    protection and trust.
                  </p>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-list-thumbnails" />
                  <h5>Enable Transparency</h5>
                  <p>
                    We provide your end users and customers useful and helpful
                    data and information on your solutions and services to help
                    them instantly evaluate personal data policies and data
                    privacy standards of your team and company.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="features features-3 d-none">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  <h3>
                    Does your organization collect personal data or care about
                    customer data?
                  </h3>

                  <p>
                    As the largest, open database of privacy ratings and reports
                    in the world, our business is making high-quality, official
                    privacy data openly available to consumers and customers.
                    Privacy and InfoSec Data that can be trusted, accessed,
                    analysed, monitored and reviewed when and how it’s needed.
                  </p>
                </div>
              </div>

              <GridIcons />
            </div>
            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <Link to="/why" className="btn btn-primary  btn-sm">
                  Learn More about Why TheLeanFounder Exists?
                </Link>
              </div>
            </div>
          </section>

          <section className="features features-3  d-none">
            <div className={"bg-color2"}>
              <div className={"container"}>
                <div className={"integration-guid"}>
                  <div className={"title"}>
                    <h2>
                      We provide data to your customers and data on your vendors
                      and many more...
                    </h2>
                    <br />
                    <br />
                  </div>
                  <DirectoryCategories />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </section>

          {/* 
    <div className={"bg-color"}>
      <div className={"container"}>
        <div className={"integration-guid"}>
          <div className={"title"}>
            <h2>How to use TheLeanFounder for Business</h2>
          </div>

          <div className={"content"}>
            <h4>Overview</h4>
            <p>
              As a service provider or business customer, you can use the site
              for free just like end users can but there's additional features
              and benefits for you.
            </p>
            <h4>Step 1</h4>
            <p>
              You can also search for any vendor, product, site, app, service,
              solution or company that's in our database.
            </p>
            

            <h4>Step 2</h4>
            <p>
              You can then view the profile of the company in question and
              relevant information, tools and data at your finger tips.
            </p>
            
            <h4>Step 3</h4>
            <p>
              You can also view more in depth business-only information if you
              or your team members create an account and save the profile in
              your dashboard in your account for future reference and to monitor
              changes to profiles.
            </p>
            
          </div>
        </div>
      </div>
    </div> */}

<div className="call-to-action">
            <div className="container">
              <div className="call-to-action__content">
                <h2>Let's talk</h2>
                <p>
                  What are your biggest problems? We'd like to help. Do you have
                  questions about possible solutions?
                </p>
              </div>
              <div className="button">
                <a href="/lets-talk/">Let's talk</a>
              </div>
            </div>
          </div>
    
          {/* <div className={"call-to-action"}>
            <div className={"container"}>
              <div className={"call-to-action__content"}>
                <h2>Sign up for free</h2>
                <p>
                  Sign up to start improving your digital world with{" "}
                  {data.site.siteMetadata.title}.
                </p>
              </div>

              <div className={"button"}>
                <Link
                  to={data.site.siteMetadata.getStartedUrl}
                  // target={"_blank"}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div> */}
        </Layout>
      </>
    )}
  />
)


export default BusinessPage
